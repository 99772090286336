.App {
    text-align: center;
    max-width: 768px;
    margin: 0 auto 2rem auto;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
}

.Stats {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
}

.StatsLikes {
    display: flex;
}

.StatsLikes svg {
    margin-right: 0.5rem;
}

.Comment {
    display: flex;
    margin: 1rem 0;
}

.Avatar {
    height: 36px;
    width: 36px;
}

.Avatar img {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    object-fit: fill;
}

.CommentData {
    padding-top: 0.5rem;
    margin-left: 1rem;
    width: 100%;
}

.CommentTitle {
    display: flex;
    justify-content: space-between;
}

.CommentName {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.CommentLikes {
    display: flex;
    margin: 0.5rem 0 0.5rem 0.5rem;
}

.CommentLikes svg {
    height: 22px;
    width: 22px;
}

.CommentLikes span {
    color: #d44f4f;
    margin-left: 0.5rem;
}

.CommentText {
    text-align: left;
    margin-top: 1rem;
    white-space: pre-wrap;
    word-break: break-all;
}

.LoadMoreBtn {
    width: 234px;
    align-self: center;
    background-color: #313439;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
}

.LoadMoreBtn:hover {
    background-color: #2b353b;
}

.LoadMoreBtn:disabled {
    background-color: #676666;
    cursor: not-allowed;
}
